'use strict';

import http from '@/utils/http';

export default {
    // 财务模块-订单管理、
    pageUrl: '/connections_treasure_box_admin_backend/payment/order-info/page',
    //
    // 用户详情订阅充值分页  local.get('userId')
    pThreeUrl: `/connections_treasure_box_admin_backend/payment/order-info/page`,
    // 户详情-金币流水
    pFourUrl: `/connections_treasure_box_admin_backend/payment/gold-detail/page`,
    // 金币管理分页、
    goldUrl: '/connections_treasure_box_admin_backend/payment/gold-detail/page',
    getPackageInfoTypeList: '/connections_treasure_box_admin_backend/payment/package/getPackageInfoTypeList',

    // 意见反馈
    feedbackUrl: '/connections_treasure_box_admin_backend/user/user-feedback/page',
    page:'/connections_treasure_box_admin_backend/user/exchange-send-record/page',

    // 版本分页
    versionPage:'/connections_treasure_box_admin_backend/system/app-version-info/page',


    // 订单数据导出
    export(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/order-info/excelExportData', {
            responseType: 'blob',
            code,
            params,
        });
    },
    getPayOverview(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/order-info/getPayOverview', { params, code });
    },
// 导出用户金币数据
    excelExportData(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/gold-detail/excelExportData', {
            responseType: 'blob',
            code,
            params,
        });
    },
//  金币总充值数查询
    getTotalRecharge(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/gold-detail/getTotalRecharge', { params, code });
    },

    //  金币总赠送值数查询
    getTotalGift(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/gold-detail/getTotalGift', { params, code });
    },

    //  金币总消耗数查询
    getTotalConsumption(code, params) {
        return http.get('/connections_treasure_box_admin_backend/payment/gold-detail/getTotalConsumption', { params, code });
    },

    //  金币总存量数查询
    getTotalStock(code, params) {
        return http.get('/connections_treasure_box_admin_backend/gold/getTotalStock', { params, code });
    },

    //     查询金币流程详情接口
    goleDetail(code, id) {
        return http.get(`/connections_treasure_box_admin_backend/payment/gold-detail/${id}`, { code });
    },

    // 充值订阅详情
    orderInfo(code, id) {
        return http.get(`/connections_treasure_box_admin_backend/payment/order-info/${id}`, { code });
    },
//     意见反馈
    feedbackDetail(code, id) {
        return http.get(`/connections_treasure_box_admin_backend/user/user-feedback/${id}`, { code });
    },

// 导出用户反馈数据
    excelExportDataFeedBack(code, params) {
        return http.get('/connections_treasure_box_admin_backend/user/user-feedback/excelExportData', {
            responseType: 'blob',
            code,
            params,
        });
    },
    // 版本号更新
    updateVersion(code, data) {
        return http.put('/connections_treasure_box_admin_backend/system/app-version-info', data, { code });
    },
    // 版本新增
    addVersion(code, data) {
        return http.post('/connections_treasure_box_admin_backend/system/app-version-info', data, { code });
    },
//    版本删除
    delete(code, params) {
        return http.delete('/connections_treasure_box_admin_backend/system/app-version-info', { params, code });
    },

};
