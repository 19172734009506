<template>
    <div class="iss-main-grid">
      <!--     分页 -->
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
            ref="gridRef"
            allow-selection
            :columns="columns"
            :code="$route.path"
            :url="url"
            :search="search"
            :btn-operation="btnOperation"
            :scroll="{ x: 1200, y: gridHeight }"
        >
          <template #province="{ text }">
            <a-tag v-if="text === true" color="success">接受</a-tag>
            <a-tag v-else-if="text === false" color="error">不接受</a-tag>
            <span v-else></span>
          </template>
          <template #avatar="{ record }">
            <div  @click="handleName(record)" class="cursor-p" >
              <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
              <a-avatar :size="30" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
          </template>
          <template #userName="{ record }">
            <div @click="handleName(record)" class="cursor-p" v-if="record.userName">{{record.userName}}</div>
            <div @click="handleName(record)" class="cursor-p" v-else>暂无</div>
          </template>

<!--          <template #packageTitle="{ record }">-->
<!--            &lt;!&ndash;        数量样式处理   &ndash;&gt;-->
<!--            <div v-if="record.packageType === 8">京东购物卡</div>-->
<!--            <div v-if="record.packageTitle === 9">话费</div>-->
<!--          </template>-->
  <!--      订单分类   -->
          <template #sendStatus="{ record }">
            <!--        数量样式处理   -->
            <div v-if="record.sendStatus === 1">已发送</div>
            <div v-if="record.sendStatus === 0">未发送</div>
          </template>
  
  
          <template #operation="{ record }">
            <operation :options="options" :record="record">
              <template #goDetail>
                <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
              </template>
              <template #more>
                <div class="primary-text cursor-p">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                    <template #overlay>
                      <a-menu @click="clickOptions($event, record)">
                        <a-menu-item
                            v-for="item in itemOption"
                            :key="item.value"
                        >
                          <span class="padding-10">{{ item.label }}</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </operation>
          </template>
  
        </grid>
      </div>
    </div>
    <iss-edit
        v-model:visible="visible"
        :initValue="activeItem"
        @fnOk="handleView"
    />
  </template>
  
  <script>
  import { reactive, ref, toRefs, } from 'vue';
  import { Modal, Menu, Dropdown, Avatar, message } from 'ant-design-vue';
  import {
    UserOutlined,
  } from '@ant-design/icons-vue';
  import Grid from '@/components/grid';
  import Operation from '@/components/operation';
  import {useRouter} from "vue-router";
  import { setAvatar} from "@/utils";
  import SearchForm from '@/components/searchForm';
  import ordersApi from "@/api/orders.js";
  import issEdit from './components/edit';
  import userApi from "@/api/user";
  
  export default {
    components: {      
      Operation,
      Grid,
      AMenu: Menu,
      AMenuItem: Menu.Item,
      ADropdown: Dropdown,
      SearchForm,
      UserOutlined,
      AAvatar: Avatar,
      issEdit

  
    },
    setup() {
      const gridRef = ref();
      const router = useRouter();
      const state = reactive({
        search: {},
        TagList: [],
        visible: false,
        activeItem: {},
    
  
  
      });
  
      const clickOptions = ({ key }, record) => {
        console.log('更多操作', key, record);
        switch (key) {
          case 'order':
            router.push(`/user/${record.userId}?activeKey=3`)
            break;
          case 'invitation':
            router.push(`/user/${record.userId}?activeKey=2`)
            break;
          case 'disable':
            Modal.confirm({
              title: '确定要封禁该账号吗?',
              onOk: () => {
                console.log('OK');
              },
            });
            break;
        }
      };
  
  
      return {
        setAvatar,
        gridRef,
        ...toRefs(state),
        itemOption: [
          { value: 'order', label: 'ta的订单' },
          { value: 'invitation', label: 'ta的邀请' },
          { value: 'disable', label: '封禁账号' },
        ],
        items: [
          {
            key: 'packageType',
            label: '兑换物品',
            type: 'select',
            dataset: [
              { label: '京东购物卡', value: 8 },
              { label: '话费', value: 9 },
            ],
          },
          {
            key: 'sendStatus',
            label: '发送状态',
            type: 'select',
            dataset: [
              { label: '已发送', value: 1 },
              { label: '未发送', value: 0 },
            ],
          },
          //  需要补上日期时间筛选
          {
            key: 'date',
            timeKey: ['startCreateTime', 'endCreateTime'],
            label: '日期范围',
            type: 'rangePicker',
          },
          {
            key: 'inputContent',
            label: '昵称、手机号',
            type: 'input',
          },

        ],
        columns: [
          { dataIndex: 'avatar', title: '头像', width: 60, ellipsis: true,
            slots: { customRender: 'avatar' },
          },
          { dataIndex: 'userName', title: '昵称', width: 180, ellipsis: true,
            slots: { customRender: 'userName' },
          },
          { dataIndex: 'packageTitle', title: '兑换物品', width: 180, ellipsis: true,
            slots: { customRender: 'packageTitle' },
          },
          { dataIndex: 'bindingPhone', title: '绑定手机', width: 180, ellipsis: true,
            slots: { customRender: 'bindingPhone' },
          },
          { dataIndex: 'sendPhone', title: '兑换手机', width: 180, ellipsis: true,
            slots: { customRender: 'sendPhone' },
          },
          { dataIndex: 'exchangeTime', title: '兑换时间', width: 180, ellipsis: true,
            slots: { customRender: 'exchangeTime' },
          },
          { dataIndex: 'sendStatus', title: '发送状态', width: 120, ellipsis: true,
            slots: { customRender: 'sendStatus' },
          },
          {
            key: 'id',
            title: '操作',
            fixed: 'right',
            width: 100,
            slots: { customRender: 'operation' },
          },
        ],
        btnOperation: [
          
        ],
        options: [
            {
            type: 'update',
            permission: 'resources:update',
              icon: 'EditTwoTone',
            fnClick: record => {
                console.log('record', record)
                state.visible = true;
                Object.assign(state.activeItem , record);

            },
            },
            {
            type: 'send',
            permission: 'resources:send',
            icon: 'CheckOutlined',
            label: '发送',
            show: (record) => {
              if (record.sendStatus === 1) {
                return false
              } else {
                return true
              }
            },
            fnClick: (record) =>{
                console.log('id', record.userId)
             let parmas = record
              parmas.sendStatus = 1
              userApi.exchangeSendRecord('', parmas).then(() => {
                message.success('操作成功');
               gridRef.value.refreshGrid()
              });
            },
            },
         
        ],
        url: ordersApi.page,
        gridHeight: document.body.clientHeight - 260,
        clickOptions,
        handleFnSearch: value => {
          const temp = Object.assign({}, value);
          // temp.tag = temp.tag?.join(',');
          state.search = temp;
        },
        handleName: (record) => {
          return router.push(`/user/${record.userId}`)
        },
        handleView: (value) => {
         console.log('value66', value)
          state.visible = value;
          gridRef.value.refreshGrid();
          
        },
  
        handleGotoView: ($event, record)=> {
          // 查询金币流水
          ordersApi.goleDetail('', record.id).then(res => {
            if (res) {
              state.activeItem = res
            }
          });
          state.visible = true
  
        },
  
      }
  
    },
  
  }
  </script>
  
  <style scoped lang="less">
  .iss-main-grid {
    padding: 0px 10px;
    max-height: calc(100vh - 88px);
    overflow-y: auto;
  }
  
  .statistics-card {
    border-radius: 4px;
    padding: 20px;
    background-color: #ffffff;
    //box-shadow: @box-shadow-base;
    .statistics-title {
      //padding: 10px 12px;
      width: 85%;
      font-size: 16px;
      word-wrap: break-word;
    }
    .statistics-item {
      //padding: 12px 20px;
      //width: 100%;
      text-align: center;
      background-color: #ffffff;
      align-items: center;
    }
    .imgBox {
      width: 15%;
      .icon {
        font-size: 28px;
      }
    }
  }
  .statistics-card[flex-attr] {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fontStyle {
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    //font-weight: 500;
    color: #313d5f;
    line-height: 24px;
  }
  .yuan {
    font-size: 14px;
  }
  
  
  </style>