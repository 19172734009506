<template>
    <a-modal
      :visible="visible"
      :title=" '编辑' "
      :mask-closable="false"
      :width="600"
      centered
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template #footer>
        <a-space>
          <a-button key="back" @click="handleCancel">取消</a-button>
          <a-button key="submit" type="primary" @click="handleOk">
            确认
          </a-button>
        </a-space>
      </template>
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        
        <a-form-item label="兑换手机号" v-bind="validateInfos.sendPhone">
          <a-input  v-model:value="form.sendPhone" placeholder="请输入手机号" />
        </a-form-item>

        <a-form-item
            label="发送状态"
            v-bind="validateInfos.sendStatus"
          >
          <a-select
                v-model:value="form.sendStatus"
                :options="classifyOptions"
                placeholder="请选择分类"
              />
          </a-form-item>
      
      </a-form>
    </a-modal>
  </template>
  <script>
  // toRaw,
  import { reactive, toRefs, watch } from 'vue';
  // import { useRoute } from 'vue-router';
  import { Form, message, Space, Select} from 'ant-design-vue';
  import { useStore } from 'vuex';
  import userApi from '@/api/user.js';
  import { validMobile, } from '@/utils/validation';
  
  export default {
    components: {
      AForm: Form,
      AFormItem: Form.Item,
      ASpace: Space,
      ASelect: Select,

    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      initValue: {
        type: Object,
        default: () => ({}),
      },
    },
    setup(props, context) {
      // const route = useRoute();
      const store = useStore();
      const required = { required: true, message: '不能为空' };
      const whitespace = {
        whitespace: true,
        message: '不能为空',
        trigger: 'change',
      };
      const form = reactive({
        id: '',
        mobile: null,
        userId: '',
        sendStatus: null,
        sendPhone: '',

      });
      const state = reactive({
        formList: [],
        agreementList: [],
        meetingList: [],
        classifyOptions:[
        {
          value: 1,
          label: '已发送',
        },
        {
          value: 0,
          label: '未发送',
        }
        ]
      });
      const { validateInfos, validate, resetFields } = Form.useForm(form, {
        sendPhone: [
          {
            validator: async (rule, sendPhone) => {
              if (sendPhone) {
                if (validMobile(sendPhone)) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入合法的手机号');
              }
              return Promise.reject('不能为空');
            },
          },
        ],

    });
      watch(
        () => props.initValue,
        initValue => {

          if (initValue.id) {
            console.log('initValue', initValue  )
            Object.assign(form, initValue);
            form.id = initValue.id

          } else {
            resetFields();
          }
        },
        { deep: true }
      );
  
      return {
        ...toRefs(state),
        form,
        store,
        validateInfos,
        handleCancel: () => {
          context.emit('update:visible', false);
        },
        handleOk: () => {
          validate().then(() => {
            let type = '';
            form.id && (type = 'exchangeSendRecord');
  
            userApi[type]('', form).then(() => {
              message.success('操作成功');
              context.emit('fnOk', false);
              // resetFields();
            });
          });
        },
      };
    },
  };
  </script>
  
  <style scoped lang="less">
  .font-color {
    color: #86909c;
  }
  .font-size {
    font-size: 12px;
  }
  </style>
  